import { render, staticRenderFns } from "./SkinsCategories.vue?vue&type=template&id=954e2bb6&"
import script from "./SkinsCategories.vue?vue&type=script&lang=js&"
export * from "./SkinsCategories.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <vx-table :resource="resource" :columns="columns" :filters="filters" />
</template>

<script>
import {
  VxTable,
  VxCellDate,
  VxCellImage,
  VxCellLink,
  VxCellRarityBadge
} from '@/components/table'
import {
  passDataToResource,
  categories
} from '@/services/resources'
import { filterTypes } from '@/services/table'
import { splitText } from '@/services/utils'
import { buildRoute, path } from '@/router'

export default {
  name: 'SkinsCategories',
  components: {
    VxTable,
    VxCellDate,
    VxCellImage,
    VxCellLink
  },
  setup () {
    const resource = passDataToResource(categories.getAll, {
      requestParams: {
        params: { include: 'file' }
      }
    })

    const getMetadata = (value) => {
      const metadata = Object.entries(value).map(
        ([key, val]) => `${key}: ${val}`
      )
      return splitText(metadata)
    }

    const columns = [
      {
        key: 'id',
        sortable: true
      },
      {
        key: 'file',
        label: 'Image',
        component: VxCellImage
      },
      {
        key: 'name',
        sortable: true,
        component: VxCellLink,
        tdAttr: (value) => ({
          label: value,
          href: buildRoute(path.items, { query: { weapon: value } })
        })
      },
      {
        key: 'variation',
        label: 'Variation'
      },
      {
        key: 'rarity',
        component: VxCellRarityBadge
      },
      {
        key: 'metadata',
        label: 'Metadata',
        formatter: (value) => {
          if (!value) return
          return getMetadata(value)
        }
      },
      {
        key: 'created_at',
        sortable: true,
        component: VxCellDate
      },
      {
        key: 'updated_at',
        sortable: true,
        component: VxCellDate
      }
    ]

    const filters = [
      { key: 'id' },
      {
        key: 'name',
        label: 'Category',
        type: filterTypes.serverSelect,
        resource: categories.getNames,
        resourceMapper: ({ names }) => names.sort()
      },
      { key: 'variation' },
      {
        key: 'rarity',
        type: filterTypes.serverSelect,
        resource: categories.getBaseInfo,
        resourceMapper: ({ rarity }) => rarity,
        optionsLabel: 'full',
        reduce: ({ short }) => short
      }
    ]

    return {
      columns,
      filters,
      resource
    }
  }
}
</script>
